var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "equipGroup-wrap" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("a-input-search", {
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入分组名称" },
            on: { search: _vm.onSearch },
          }),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              !_vm.isCheckbox
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.isCheckbox = true
                        },
                      },
                    },
                    [_vm._v(" 批量删除 ")]
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "10px" },
                          on: {
                            click: function ($event) {
                              _vm.isCheckbox = false
                            },
                          },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onDeleteBatch },
                        },
                        [_vm._v(" 确定 ")]
                      ),
                    ],
                    1
                  ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.addVisible = true
                    },
                  },
                },
                [_vm._v(" 添加分组 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "添加设备分组",
            visible: _vm.addVisible,
            "ok-text": "确认",
            "cancel-text": "取消",
          },
          on: {
            ok: _vm.onAddSubmit,
            cancel: function ($event) {
              _vm.addVisible = false
            },
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "addGroupForm", attrs: { model: _vm.addForm } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "分组名称",
                    prop: "groupName",
                    rules: [
                      {
                        required: true,
                        message: "请输入分组名称",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.addForm.groupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "groupName", $$v)
                      },
                      expression: "addForm.groupName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "分组描述" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: "请输入分组描述",
                      "auto-size": { minRows: 3, maxRows: 5 },
                    },
                    model: {
                      value: _vm.addForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "remark", $$v)
                      },
                      expression: "addForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          "row-selection": _vm.isCheckbox
            ? {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              }
            : null,
          columns: _vm.columns,
          "data-source": _vm.tableData,
          "row-key": "id",
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (text, record, index) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.listParams.page - 1) * _vm.listParams.pageSize +
                        index +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    staticStyle: { "margin-right": "20px" },
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.gotoDetails(record)
                      },
                    },
                  },
                  [_vm._v("详情")]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onDeleteItem([record.id])
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("Pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowPage,
            expression: "isShowPage",
          },
        ],
        staticClass: "pagination",
        attrs: { "current-page": _vm.listParams.page, count: _vm.total },
        on: { handleChange: _vm.changePage, handleChangeSize: _vm.changeSize },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }