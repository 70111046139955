<template>
  <div class="equipGroup-wrap">
    <div class="header">
      <a-input-search
        placeholder="请输入分组名称"
        style="width: 200px"
        @search="onSearch"
      />
      <div style="display: flex">
        <a-button v-if="!isCheckbox" type="primary" @click="isCheckbox = true">
          批量删除
        </a-button>
        <div v-else>
          <a-button style="margin-right: 10px" @click="isCheckbox = false">
            取消
          </a-button>
          <a-button type="primary" @click="onDeleteBatch">
            确定
          </a-button>
        </div>
        <a-button
          style="margin-left: 15px"
          type="primary"
          @click="addVisible = true"
        >
          添加分组
        </a-button>
      </div>
    </div>
    <!-- 添加分组的模态框 -->
    <a-modal
      :width="800"
      title="添加设备分组"
      :visible="addVisible"
      ok-text="确认"
      cancel-text="取消"
      @ok="onAddSubmit"
      @cancel="addVisible = false"
    >
      <a-form-model ref="addGroupForm" :model="addForm">
        <a-form-model-item
          label="分组名称"
          prop="groupName"
          :rules="[
            { required: true, message: '请输入分组名称', trigger: 'change' },
          ]"
        >
          <a-input
            v-model="addForm.groupName"
            placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头"
          />
        </a-form-model-item>
        <a-form-model-item label="分组描述">
          <a-textarea
            v-model="addForm.remark"
            placeholder="请输入分组描述"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-table
      :row-selection="
        isCheckbox
          ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange }
          : null
      "
      :columns="columns"
      :data-source="tableData"
      row-key="id"
      :pagination="false"
    >
      <template slot="index" slot-scope="text, record, index">
        <span>{{
          (listParams.page - 1) * listParams.pageSize + index + 1
        }}</span>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a
          href="javascript:void(0);"
          style="margin-right: 20px"
          @click="gotoDetails(record)"
          >详情</a
        >
        <a href="javascript:void(0);" @click="onDeleteItem([record.id])"
          >删除</a
        >
      </template>
    </a-table>
    <Pagination
      class="pagination"
      v-show="isShowPage"
      :current-page="listParams.page"
      :count="total"
      @handleChange="changePage"
      @handleChangeSize="changeSize"
    />
  </div>
</template>

<script>
import {
  getdeviceGroupList,
  deviceGroupSave,
  deviceGroupBatchRemoveById,
} from "@/api/equipGroup.js";
import { columns } from "./tableData";
import Pagination from "@/components/Pagination";
export default {
  name: "index",
  components: {
    Pagination,
  },
  data() {
    return {
      //查询列表数据所需参数集合
      listParams: {
        page: 1, //当前页码
        pageSize: 10, //每页条数
        groupName: "", //设备分组名称
        sortItems: {
          //排序字段列表 ,示例值([ { "column": "age", "asc": false } ])
          asc: "", //排序方向：true升序，fase降序 ,示例值(true)
          column: "", //需要进行排序的字段 ,示例值(createBy)
        },
      },
      total: 0, //总条数，
      isShowPage: true,
      columns,
      addVisible: false,
      //添加分组表单数据
      addForm: {
        groupName: "", //分组名称
        remark: "", //分组描述
      },
      //表格数据
      tableData: [],
      // 设备列表多选数据集合
      selectedRowKeys: [],
      //是否显示复选框
      isCheckbox: false,
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    //根据分页查询接口获取表格数据
    getTableData() {
      getdeviceGroupList(this.listParams).then((res) => {
        if (res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    //根据名称搜索分组
    onSearch(val) {
      this.listParams.groupName = val;
      this.getTableData();
    },
    //处理添加分组
    onAddSubmit() {
      this.$refs.addGroupForm.validate((valid) => {
        if (valid) {
          deviceGroupSave(this.addForm).then((res) => {
            this.addVisible = false;
            this.$message.success("添加分组成功！");
            this.getTableData()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //查看分组详情
    gotoDetails(item) {
      this.$router.push(`/equip-group/details?id=${item.id}`);
    },
    //删除分组
    onDeleteItem(params, isBatch = false) {
      this.$confirm({
        title: "您确认删除分组吗？",
        okText: "确定",
        centered: true,
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          deviceGroupBatchRemoveById(params)
            .then(() => {
              this.getTableData();
              this.$message.success("删除成功！");
              if (isBatch) this.isCheckbox = false;
            })
            .catch((error) => {
              console.log(error);
            });
        },
      });
    },
    //批量删除
    onDeleteBatch() {
      this.onDeleteItem(this.selectedRowKeys, true);
    },
    // 选择分页
    changePage(page) {
      this.listParams.page = page;
      this.getTableData();
    },
    // 修改每页数据条数
    changeSize(size) {
      this.listParams.pageSize = size;
      this.getTableData();
    },
    // 设备列表中的多选事件
    onSelectChange(val) {
      this.selectedRowKeys = val;
    },
  },
};
</script>

<style scoped lang="scss">
.equipGroup-wrap {
  padding: 20px 40px;
  .header {
    // height: 70px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .ant-pagination {
    text-align: right;
  }
  ::v-deep .ant-pagination-total-text {
    float: left;
  }
  .pagination {
    margin-top: 10px;
  }
}
</style>
