export const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: '',
        align: 'center',
        scopedSlots: { customRender: 'index' },
    },
    {
        title: '分组名称',
        dataIndex: 'groupName',
        key: '',
        align: 'center'
    },
    {
        title: '分组ID',
        dataIndex: 'id',
        key: '',
        align: 'center'
    },
    {
        title: '添加时间',
        dataIndex: 'createTime',
        key: '',
        align: 'center'
    },
    {
        title: '分组描述',
        dataIndex: 'remark',
        key: '',
        align: 'center'
    },
    {
        title: '设备数量',
        dataIndex: 'deviceCount',
        key: '',
        align: 'center'
    },
    {
        title: '操作',
        dataIndex: 'handle',
        key: '',
        scopedSlots: { customRender: 'operation' },
        align: 'center'
    }
]
export const detailsColumns = [
    {
        title: '设备名称',
        dataIndex: 'deviceName',
        key: '',
        align: 'center'
    },
    {
        title: '产品名称',
        dataIndex: 'remark',
        key: '',
        align: 'center'
    },
    {
        title: '设备状态',
        dataIndex: 'status',
        key: '',
        align: 'center',
        scopedSlots: { customRender: 'status' },
    },
    {
        title: '操作',
        dataIndex: 'handle',
        key: '',
        scopedSlots: { customRender: 'operation' },
        align: 'center'
    }
]